"use strict";

import '../css/main.scss';
import "core-js/stable";
import "regenerator-runtime/runtime";

class landing {
	constructor() {
		let _self = this;

		this.config = {
			host: window.location.protocol + '//' + window.location.hostname,
			debug: false,
			ip: null,
			geoCountry: null
		};

		this.elements = {
			dynamic: {}
		};

		this.init().then(function(config) {
			_self.detectLocale(config['subdomains']).then(function(locale) {
				_self.parseTranslations(locale).then(function() {
					_self.detectOffer().then(function (offer) {
						_self.config.offer = offer;
						_self.populateContent().then(function() {
							if(_self.config.brand.isRTG) {
								_self.initRtgHeader();
							}
							document.body.classList.remove('--loading');
							if(_self.config.debug) {
								console.info('Affiliate landing page content successfully populated >>>', _self);
							}
						});
					}).catch(function (error) {
						console.warn('Failed to detect offer >>>', error);
						_self.populateContent().then(function() {
							if(_self.config.brand.isRTG) {
								_self.initRtgHeader();
							}
							document.body.classList.remove('--loading');
							if(_self.config.debug) {
								console.warn('Affiliate landing page content populated with errors >>>', _self);
							}
						});
					});
				});
			}).catch(function(error){
				_self.error(error);
			});
		}).catch(function(error){
			_self.error(error);
		});
	}

	error(error) {
		// @TODO: Flesh out error handler
		console.error('An error has occurred >>>', error);
	}

	init() {
		let _self = this;

		window.addEventListener('evtArisRegFormLoaded', function(){
			try {
				_self.config.brand.offer_expiry = _self.config.brand.offer_expiry || 14;
				_self.config.brand.offer_expiry = parseInt(_self.config.brand.offer_expiry);
				_self.config.offer.days_valid_after_reg = _self.config.offer.days_valid_after_reg || _self.config.brand.offer_expiry;
				_self.config.offer.days_valid_after_reg = parseInt(_self.config.offer.days_valid_after_reg);

				Cookies.set('aff_offer', _self.config.offer, {
					expires: _self.config.brand.offer_expiry,
					path: '/',
					domain: window.location.hostname.replace(/^(.*?)\./gi, '.')
				});

				let aff = window.location.search.match(/aff=(.+?)(&|$)/im);
				if(Array.isArray(aff)) aff = aff[1] || null;

				if(aff !== null) {
					Cookies.set('aff', aff, {
						expires: 3,
						path: '/',
						domain: window.location.hostname.replace(/^(.*?)\./gi, '.')
					});
				}
			} catch (error) {
				_self.error(error);
			}
		});

		return new Promise(function(resolve, reject){
			try {
				_self.config.host = window.location.protocol + '//' + window.location.hostname;
				_self.config.baseDomain = window.location.hostname.replace(/^(.*?)\./gi, '');
				_self.config.environment = _self.detectEnv();
				_self.config.debug = _self.config.environment !== 'production';

				switch(_self.config.environment) {
					case 'staging':
						_self.config.sourceURL = 'https://ca-universal.s3.amazonaws.com/welcome/staging';
						break;
					case 'development':
						_self.config.sourceURL = 'https://ca-universal.s3.amazonaws.com/welcome/dev';
						break;
					case 'production':
					default:
						_self.config.sourceURL = 'https://ca-assets.cerebrospace.net/welcome/dist';
						break;
				}

				fetch(_self.config.sourceURL + '/config.json').then(function(response) {
					if(response.status >= 200 && response.status < 300) {
						response.json().then(function (config) {
							_self.config = Object.assign(_self.config, config);
							_self.detectBrand().then(function(){
								if(!_self.config.brand.isRTG) {
									_self.getUserGEO(function(){
										_self.initRegistrationForm();
									});
								}
								resolve(_self.config);
							});
						}).catch(function (error) {
							reject(error);
						});
					} else {
						throw new Error(response.status.toString());
					}
				});
			} catch (error) {
				reject(error);
			}
		});
	}

	initGoogleAnalytics() {
		if(this.config.brand.hasOwnProperty('googleTagID') && this.config.brand['googleTagID'] !== null) {
			let googleTagScript = document.createElement('script');
			let googleTagNoScript = document.createElement('noscript');

			googleTagScript.innerHTML = `<!-- Google Tag Manager (Welcome Page) -->
	                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	                })(window,document,'script','dataLayer','` + this.config.brand['googleTagID'] + `');
	                <!-- End Google Tag Manager (Welcome Page) -->`;

			googleTagNoScript.innerHTML = `<!-- Google Tag Manager (noscript - Welcome Page) -->
    <iframe src="https://www.googletagmanager.com/ns.html?id=` + this.config.brand['googleTagID'] + `" height="0" width="0" style="display:none;visibility:hidden"></iframe>
    <!-- End Google Tag Manager (noscript - Welcome Page) -->`;

			document.head.appendChild(googleTagScript);
			document.body.prepend(googleTagNoScript);
		}
	}

	initRegistrationForm() {
		let _self = this;

		_self.config.brand.reg_config = _self.config.brand.reg_config || {};
		if (typeof _self.config.brand.reg_config !== 'object') {
			_self.config.brand.reg_config = {};
		}

		let regJsConfig = Object.assign({
			debug: _self.config.debug,
			koreEndpoint: _self.config.brand.kore_url,
			koreVersion: 3,
			brand: _self.config.brand['brand_id'],
			locale: _self.config.locale,
			playMode: 'Real',
			returnURL: _self.config.brand['website_url'],
			geoCountry: _self.config.geoCountry,
			ip: _self.config.ip,
		}, _self.config.brand.reg_config);

		switch (_self.config.environment) {
			case 'staging':
				_self.config.regJsUrl = 'https://ca-universal.s3.amazonaws.com/reg/staging/reg.js';
				regJsConfig.stylesheet = 'https://ca-universal.s3.amazonaws.com/reg/staging/default.css';
				regJsConfig.returnURL = regJsConfig.returnURL.replace(/www\./gi, 'staging.');
				break;
			case 'development':
				_self.config.regJsUrl = 'https://ca-universal.s3.amazonaws.com/reg/dev/reg.js';
				regJsConfig.stylesheet = 'https://ca-universal.s3.amazonaws.com/reg/dev/default.css';
				regJsConfig.returnURL = regJsConfig.returnURL.replace(/www\./gi, 'dev.');
				break;
			case 'production':
			default:
				_self.config.regJsUrl = 'https://ca-assets.cerebrospace.net/reg/dist-1/reg.js';
				regJsConfig.stylesheet = 'https://ca-assets.cerebrospace.net/reg/dist-1/default.css';
				break;
		}

		let regJsTag = document.createElement('script');
		regJsTag.setAttribute('src', _self.config.regJsUrl);

		regJsTag.onload = function () {
			if (typeof initArisRegistration === 'function') {
				try {
					initArisRegistration(regJsConfig);
				} catch (error) {
					_self.error(error);
				}
			}
		}

		document.body.appendChild(regJsTag);
	}

	initRtgHeader() {
		let _self = this;
		let cookiesJS = document.createElement('script');
		cookiesJS.setAttribute('type', 'application/javascript');
		cookiesJS.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/js-cookie/2.2.1/js.cookie.min.js');
		cookiesJS.setAttribute('integrity', 'sha512-Meww2sXqNHxI1+5Dyh/9KAtvI9RZSA4c1K2k5iL02oiPO/RH3Q30L3M1albtqMg50u4gRTYdV4EXOQqXEI336A==');
		cookiesJS.setAttribute('crossOrigin', 'anonymous');
		cookiesJS.setAttribute('referrerpolicy', 'no-referrer');
		cookiesJS.onload = function() {
			new Promise(function(resolve){
				document.body.setAttribute('data-group', 'RTG');
				document.querySelector('.welcome__offer-reg-wrapper').remove();
				let offerHeader = document.querySelector('[data-bind="offer_content"]');
				offerHeader.classList.remove('lg:col-span-2');
				offerHeader.classList.remove('lg:text-right');
				offerHeader.classList.add('lg:col-span-3');
				offerHeader.classList.add('lg:text-center');
				let providers = document.querySelector('[data-bind="providers"]');
				providers.classList.remove('justify-between');
				providers.classList.add('justify-center');
				_self.elements.rtgRegButton = document.createElement('a');
				_self.elements.rtgRegButton.setAttribute('data-registration-link', 'true');
				_self.elements.rtgRegButton.setAttribute('href', '#welcome_offer');
				_self.elements.rtgRegButton.innerText = 'Register To Play';
				_self.elements.rtgRegButtonWrapper = document.createElement('main');
				_self.elements.rtgRegButtonWrapper.classList.add('welcome__offer-rtg-reg-wrapper');
				_self.elements.rtgRegButtonWrapper.classList.add('relative');
				_self.elements.rtgRegButtonWrapper.classList.add('lg:col-span-3');
				_self.elements.rtgRegButtonWrapper.classList.add('mb-4');
				_self.elements.rtgRegButtonWrapper.classList.add('mx-auto');
				_self.elements.rtgRegButtonWrapper.classList.add('text-center');
				_self.elements.rtgRegButtonWrapper.classList.add('-z-middle-front');
				_self.elements.rtgRegButtonWrapper.appendChild(_self.elements.rtgRegButton);
				offerHeader.parentElement.appendChild(_self.elements.rtgRegButtonWrapper);
				resolve();
			}).then(function() {
				setTimeout(function() {
					window.rtgLinks = document.querySelectorAll('[data-registration-link]');
					if (rtgLinks !== null) {
						const basehost = window.location.hostname.replace(/^(.*?)\./gi, '');
						let port;

						switch (basehost) {
							case 'africangrand.com':
								port = 2572;
								break;
							case 'juicyvegas.com':
								port = 3072;
								break;
							case 'wildjoker.com':
								port = 2072;
								break;
						}

						let baseLink = 'https://download.' + basehost + '/affiliate/remote/AidDownload.asp?';
						let redirectLink = 'https://download.' + basehost + '/smartdownloadcasino.asp?redirect=https://cdk.' + basehost + ':' + port + '/Lobby.aspx?skinId=1&externalCommand=signUp';

						const cookies = Cookies.get();
						for (let c in cookies) {
							if (cookies.hasOwnProperty(c)) {
								switch (c) {
									case 'casinoID':
										baseLink += c + '=' + cookies[c] + '&';
										break;
									case 'gAID':
										baseLink += c + '=' + cookies[c] + '&';
										break;
									case 'subGid':
										baseLink += c + '=' + cookies[c] + '&';
										break;
									case 'bannerID':
										baseLink += c + '=' + cookies[c] + '&';
										break;
									case 'trackingID':
										baseLink += c + '=' + cookies[c] + '&';
										break;
								}
							}
						}

						baseLink += 'redirect=' + encodeURIComponent(redirectLink);

						rtgLinks.forEach(function (rtgAnchor) {
							rtgAnchor.setAttribute('href', baseLink);
						});
					}
				}, 500);
			}).catch(function(error){
				_self.error(error);
			});
		}
		document.body.appendChild(cookiesJS);
	}

	detectEnv() {
		if(window.location.href.match(/^https?:\/\/(.+?)\/staging\//gi) !== null) {
			return 'staging';
		} else if(window.location.pathname !== '/' || window.location.protocol.indexOf('http') === -1) {
			return 'development';
		}

		return 'production';
	}

	detectBrand() {
		let _self = this;
		return new Promise(function(resolve){
			let urlPattern = window.location.hostname.match(/^(.+?)\.(bet)?(.+?)\.(.+?)$/im);
			_self.config.subdomain = urlPattern[1];
			_self.config.brand = _self.config['brands'][urlPattern[3]];

			if(typeof _self.config.brand === 'undefined') {
				_self.config.brand = _self.config['brands']['_default'];
			}

			if(typeof _self.config.brand['registration_type'] === 'undefined') {
				_self.config.brand.registration_type = _self.config['brands']['_default']['registration_type'];
			}

			if(typeof _self.config.brand['featured_games'] === 'undefined') {
				_self.config.brand.featured_games = _self.config['brands']['_default']['featured_games'];
			}

			if(typeof _self.config.brand['kore_url'] === 'undefined') {
				_self.config.brand.kore_url = _self.config['brands']['_default']['kore_url'];
			}

			if(typeof _self.config.brand['website_url'] === 'undefined') {
				_self.config.brand.website_url = _self.config['brands']['_default']['website_url'];
			}

			if(typeof _self.config.brand['style'] === 'undefined') {
				_self.config.brand.style = _self.config['brands']['_default']['style'];
			}

			if(['JuicyVegas', 'WildJoker', 'AfricanGrand'].indexOf(_self.config.brand['brand_id']) > -1) {
				_self.config.brand.isRTG = true;
				_self.config.imgCDN = 'https://d3sre66aqsdpjf.cloudfront.net/menu4-5/rtg-v2';
			} else {
				_self.config.brand.isRTG = false;
				_self.config.imgCDN = 'https://d3sre66aqsdpjf.cloudfront.net/menu4-5/v2';
			}

			_self.setMeta();

			document.body.setAttribute('data-brand', _self.config.brand['brand_id']);
			document.querySelector('.welcome__offer').setAttribute('id', 'welcome_offer');

			resolve();
		});
	}

	setMeta() {
		this.elements.dynamic.meta = {};
		if(this.config.brand.hasOwnProperty('meta') && typeof this.config.brand['meta'] === 'object') {
			for(let key in this.config.brand['meta']) {
				if(this.config.brand['meta'].hasOwnProperty(key)) {
					if (key === 'title') {
						document.title = this.config.brand['meta'][key];
						this.elements.dynamic.meta[key] = document.querySelector('title');
					} else {
						this.elements.dynamic.meta[key] = document.createElement('meta');
						this.elements.dynamic.meta[key].setAttribute('name', key);
						this.elements.dynamic.meta[key].setAttribute('content', this.config.brand['meta'][key]);
						document.head.appendChild(this.elements.dynamic.meta[key]);
					}
				}
			}
		}
	}

	getDefaultOffer() {
		let _self = this;
		return new Promise(function(resolve, reject) {
			_self.config.locale = _self.config.locale || 'en-US';
			fetch(_self.config.sourceURL + '/content/' + _self.config.locale + '/default_offers/' + _self.config.brand['brand_id'].toLowerCase() + '_default_offer.json').then(function(response){
				if(response.status >= 200 && response.status < 300) {
					response.json().then(function (offer) {
						resolve(offer);
					}).catch(function(error) {
						reject(error);
					});
				} else {
					reject(new Error(response.status.toString()));
				}
			}).catch(function(error) {
				reject(error);
			});
		});
	}

	detectOffer() {
		let _self = this;
		return new Promise(function(resolve, reject){
			try {
				if(window.location.search === '') {
					_self.getDefaultOffer().then(function(offer){
						resolve(offer);
					}).catch(function(error){
						reject(error);
					});
				} else {
					let offerID = window.location.search.match(/offer_id=(.+?)(&|$)/im);
					if(Array.isArray(offerID)) offerID = offerID[1] || null;

					_self.config['locale'] = _self.config['locale'] || 'en-US';

					if(typeof offerID === 'string' && offerID.trim() !== '') {
						// @TODO: Add locale when appropriate
						fetch('https://ca-universal.s3.amazonaws.com/welcome/affiliate_offers/' + _self.config.brand['brand_id'].toLowerCase() + '/' + offerID + '.json').then(function (response) {
							if (response.status >= 200 && response.status < 300) {
								response.json().then(function (offer) {
									if(offer.hasOwnProperty('geo') && Array.isArray(offer['geo'])) {
										let difference = offer['geo']['whitelist'].filter(x => !offer['geo']['blacklist'].includes(x));
										if(offer['geo']['blacklist'].indexOf(_self.config.geoCountry) > -1 || (difference.length > 0 && difference.indexOf(_self.config.geoCountry) === -1)) {
											_self.showPopup({
												title: 'Offer Not Available',
												message: 'The current offer is not available in your country. A global offer has been applied.'
											});
											_self.getDefaultOffer().then(function(defaultOffer){
												resolve(defaultOffer);
											}).catch(function(error){
												reject(error);
											});
										} else {
											resolve(offer);
										}
									} else {
										resolve(offer);
									}
								});
							} else {
								throw new Error('Offer with ID "' + offerID + '" not found in ' + _self.config.brand['brand_id'].toLowerCase() + ' bucket');
							}
						}).catch(function (error) {
							console.error('Failed to obtain offer with ID "' + offerID + '", loading default >>>', error);
							_self.getDefaultOffer().then(function (offer) {
								resolve(offer);
							}).catch(function (error) {
								reject(error);
							});
						});
					} else {
						_self.getDefaultOffer().then(function(offer){
							resolve(offer);
						}).catch(function(error){
							reject(error);
						});
					}
				}
			} catch (error) {
				console.warn('Default offer for brand failed to load >>>', error);
				reject(error);
			}
		})
	}

	detectLocale(subdomains) {
		let _self = this;
		return new Promise(function(resolve, reject){
			try {
				let locale = 'en-US';
				for(let subdomain in subdomains) {
					if(subdomains.hasOwnProperty(subdomain)) {
						if(_self.config.host.indexOf(subdomain) > -1) {
							locale = subdomains[subdomain]['locale'];
						}
					}
				}
				_self.config.locale = locale;
				document.body.setAttribute('data-locale', locale);
				resolve(locale);
			} catch (error) {
				reject(error);
			}
		});
	}

	isValidIP(ip) {
		const _self = this;
		try {
			return typeof ip === 'string' && /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip);
		} catch (error) {
			_self.error({
				level: 'Warning',
				originalError: error,
				consoleMessage: 'Failed to to assert valid IP Address'
			});
			return false;
		}
	}

	getUserGEO(callback = null) {
		const _self = this;
		try {
			fetch('https://www.cloudflare.com/cdn-cgi/trace').then(function (response) {
				response.text().then(function (body) {
					let ipMatch = body.match(/ip=(.+?)\n/gi);
					if (Array.isArray(ipMatch) && typeof ipMatch[0] === 'string') {
						let ip = ipMatch[0].split('=')[1];
						if (!_self.isValidIP(_self.config.ip) && typeof ip === 'string' && _self.isValidIP(ip.trim())) {
							_self.config.ip = ip.trim();
						} else {
							_self.config.ip = null;
						}
					} else {
						_self.config.ip = null;
					}

					let locMatch = body.match(/loc=(.+?)\n/gi);
					if (Array.isArray(locMatch) && typeof locMatch[0] === 'string') {
						let loc = locMatch[0].split('=')[1];
						if (typeof loc === 'string' && loc.trim().length === 2 && _self.config.geoCountry === null) {
							_self.config.geoCountry = loc.trim().toUpperCase();
						}
					}
				});
			});
		} catch (error) {
			_self.error(error);
		}

		if (typeof callback === 'function') {
			callback();
		}
	}

	parseTranslations(locale) {
		let _self = this;
		return new Promise(function(resolve, reject){
			try {
				if(_self.config.translations.hasOwnProperty(locale)) {
					_self.config.translations = _self.config.translations[locale];
				} else {
					_self.config.translations = _self.config.translations['en-US'];
				}
				resolve(_self.config.translations);
			} catch (error) {
				reject(error);
			}
		});
	}

	populateContent() {
		let _self = this;

		let docRoot = document.querySelector(':root');
		docRoot.style.setProperty('--brand-color', _self.config.brand.style['brand_color']);
		docRoot.style.setProperty('--brand-color-transparent-dark', _self.config.brand.style['brand_color'] + '90');
		docRoot.style.setProperty('--accent-color', _self.config.brand.style['accent_color']);
		docRoot.style.setProperty('--hover-accent-color', _self.config.brand.style['hover_accent_color']);

		_self.initGoogleAnalytics();

		window.blueIonAffiliateLanding = this;

		return new Promise(function(resolve, reject) {
			try {
				_self.elements.translatable = document.querySelectorAll('[data-translate]');
				_self.elements.bound = document.querySelectorAll('[data-bind],[data-prepend]');

				_self.elements.bound.forEach(function(el){
					let bindRef = el.getAttribute('data-bind');
					let prependRef = el.getAttribute('data-prepend');

					switch(bindRef) {
						case 'logo':
							_self.elements.dynamic.logo = document.createElement('img');
							_self.elements.dynamic.logo.setAttribute('src', _self.config.sourceURL + '/img/logos/' + _self.config.brand['brand_id'].toLowerCase() + '.png');
							_self.elements.dynamic.logo.setAttribute('alt', _self.config.brand['nicename'] + ' Logo');
							_self.elements.dynamic.logo.setAttribute('class', 'relative mx-auto');
							el.appendChild(_self.elements.dynamic.logo);
							el.classList.remove('--loading');
							break;
						case 'offer_background':
							_self.elements.dynamic.offer_background = el;
							el.style.backgroundImage = "url('" + _self.config.offer['background_image'] + "')";
							el.style.backgroundPosition = 'center';
							el.style.backgroundSize = 'cover';
							el.style.backgroundRepeat = 'no-repeat';
							el.classList.remove('--loading');
							break;
						case 'offer_content':
							_self.elements.dynamic.offer_content = {}
							if(_self.config.offer['top_offer_text'].trim() !== '') {
								_self.elements.dynamic.offer_content.top_offer_text = document.createElement('h1');
								_self.elements.dynamic.offer_content.top_offer_text.innerText = _self.config.offer['top_offer_text'];
								el.appendChild(_self.elements.dynamic.offer_content.top_offer_text);
							}
							if(_self.config.offer['top_offer_code'].trim() !== '') {
								_self.elements.dynamic.offer_content.top_offer_code = document.createElement('h3');
								_self.elements.dynamic.offer_content.top_offer_code.innerText = _self.config.offer['top_offer_code'];
								el.appendChild(_self.elements.dynamic.offer_content.top_offer_code);
							}
							if(_self.config.offer['second_offer_text'].trim() !== '') {
								_self.elements.dynamic.offer_content.second_offer_text = document.createElement('h2');
								_self.elements.dynamic.offer_content.second_offer_text.innerText = _self.config.offer['second_offer_text'];
								el.appendChild(_self.elements.dynamic.offer_content.second_offer_text);
							}
							if(_self.config.offer['second_offer_code'].trim() !== '') {
								_self.elements.dynamic.offer_content.second_offer_code = document.createElement('h3');
								_self.elements.dynamic.offer_content.second_offer_code.innerText = _self.config.offer['second_offer_code'];
								el.appendChild(_self.elements.dynamic.offer_content.second_offer_code);
							}
							el.classList.remove('--loading');
							break;
						case 'offer_image':
							if(typeof _self.config.offer['left_image'] === 'string' && _self.config.offer['left_image'].trim() !== '') {
								let altText = _self.config.offer['top_offer_text'] || _self.config.offer['second_offer_text'] || 'Offer Image';
								_self.elements.dynamic.offer_image = document.createElement('img');
								_self.elements.dynamic.offer_image.setAttribute('src', _self.config.offer['left_image']);
								_self.elements.dynamic.offer_image.setAttribute('alt', _self.config.brand['nicename'] + ': ' + altText);
								el.appendChild(_self.elements.dynamic.offer_image);
							}
							el.classList.remove('--loading');
							break;
						case 'featured_games_list':
							_self.elements.dynamic.games = {};
							for(let i=0; i < _self.config.brand.featured_games.length; i++) {
								let gameID = _self.config.brand.featured_games[i];
								let gameCat = gameID.split('_').reverse()[0];
								_self.elements.dynamic.games[gameID] = {
									picture: document.createElement('picture'),
									img: document.createElement('img')
								};
								_self.elements.dynamic.games[gameID].picture.setAttribute('class', 'welcome__featured-game');
								_self.elements.dynamic.games[gameID].img.setAttribute('src', _self.config.imgCDN + '/' + gameCat + '/' + gameID.replace(/_/gim, '-') + '.jpg');
								_self.elements.dynamic.games[gameID].img.setAttribute('loading', 'lazy');
								_self.elements.dynamic.games[gameID].img.setAttribute('alt', gameID.replace(/_/gim, ' '));
								_self.elements.dynamic.games[gameID].link = document.createElement('a');
								_self.elements.dynamic.games[gameID].link.setAttribute('href', '#welcome_offer');
								_self.elements.dynamic.games[gameID].link.setAttribute('aria-label', 'Scroll To Registration Form');
								_self.elements.dynamic.games[gameID].link.appendChild(_self.elements.dynamic.games[gameID].img);
								_self.elements.dynamic.games[gameID].picture.appendChild(_self.elements.dynamic.games[gameID].link);
								el.appendChild(_self.elements.dynamic.games[gameID].picture);
							}
							document.querySelector('.welcome__featured-games').classList.remove('--loading');
							break;
						case 'trust_factor_image':
							if(_self.config.brand.hasOwnProperty('trust_factor') && typeof _self.config.brand['trust_factor'] === 'string' && _self.config.brand['trust_factor'].trim() !== '') {
								_self.elements.dynamic.trust_factor_image = document.createElement('img');
								_self.elements.dynamic.trust_factor_image.setAttribute('src', _self.config.brand['trust_factor']);
								_self.elements.dynamic.trust_factor_image.setAttribute('loading', 'lazy');
								_self.elements.dynamic.trust_factor_image.setAttribute('alt', _self.config.brand['nicename'] + ' Trust Factor');
								el.appendChild(_self.elements.dynamic.trust_factor_image);
							}
							el.classList.remove('--loading');
							break;
						case 'about_content':
							fetch(_self.config.sourceURL + '/content/' + _self.config.locale + '/about/' + _self.config.brand['brand_id'].toLowerCase() + '_about.html').then(function(response){
								if(response.status >= 200 && response.status < 300) {
									response.text().then(function (htmlContent) {
										el.innerHTML = _self.cleanupHTML(htmlContent);
										_self.elements.dynamic.about = el;
										el.classList.remove('--loading');
									});
								} else {
									throw new Error(response.status.toString());
								}
							}).catch(function(error){
								console.warn('No about content found for "' + _self.config.brand['brand_id'] + '" >>>', error);
							});
							break;
						case 'terms':
							_self.elements.dynamic.terms = el;

							if(_self.config.brand.hasOwnProperty('terms') && typeof _self.config.brand.terms === 'string' && _self.config.brand.terms.trim() !== '') {
								_self.elements.dynamic.terms.innerHTML += '<p>' + _self.config.brand['terms'] + '</p>';
							}

							_self.elements.dynamic.terms.innerHTML += '<p data-translate="all_rights_reserved">All Rights Reserved.</p><p>© ' + (new Date()).getFullYear() + ' - ' + _self.config.brand['nicename'] + '</p>';
							el.classList.remove('--loading');
							break;
						case 'providers':
							if(_self.config.brand.hasOwnProperty('providers') && Array.isArray(_self.config.brand['providers']) && _self.config.brand['providers'].length > 0) {
								_self.elements.dynamic.providers = el;
								for(let i = 0; i < _self.config.brand['providers'].length; i++) {
									if(typeof _self.config.brand['providers'][i] === 'string') {
										_self.elements.dynamic.providers.innerHTML += '<picture><img src="' + _self.config.sourceURL + '/img/providers/' + _self.config.brand['providers'][i].toLowerCase() + '.png" loading="lazy" alt="Logo ' + _self.config.brand['providers'][i] + '"/></picture>';
									}
								}
							} else {
								el.remove();
							}
							el.classList.remove('--loading');
							break;
						default:
							break;
					}

					switch (prependRef) {
						case 'affiliate_logo':
							if(_self.config.hasOwnProperty('offer') && _self.config.offer.hasOwnProperty('affiliate_logo') && typeof _self.config.offer['affiliate_logo'] === 'string' && _self.config.offer['affiliate_logo'].trim() !== '') {
								_self.elements.dynamic.affiliate_logo_figure = document.createElement('figure');
								_self.elements.dynamic.affiliate_logo_figure.classList.add('welcome__affiliate-logo');
								_self.elements.dynamic.affiliate_logo_figure.classList.add('--fade');
								_self.elements.dynamic.affiliate_logo_figure.classList.add('--loading');
								_self.elements.dynamic.affiliate_logo_figure.innerHTML = `<figcaption data-translate="in_partnership_with">In Partnership With:</figcaption><picture><img src="` + _self.config.offer['affiliate_logo'] + `" loading="lazy" alt="Affiliate Logo"/></picture>`;
								el.prepend(_self.elements.dynamic.affiliate_logo_figure);
								_self.elements.dynamic.affiliate_logo_figure.classList.remove('--loading');
							}
							break;
						default:
							break;
					}
				});

				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	cleanupHTML(html) {
		try {
			// Remove styling, ids and classes
			html = html.replace(/(\s+)?(style|id|class)="(.+?)"(\s+)?/gim, '');
			// Replace all instances of h1 with h3
			html = html.replace(/<h2>(.+?)<\/h2>/gim, "<h3>$1</h3>").replace(/<h1>(.+?)<\/h1>/gim, "<h2>$1</h2>");
			// Remove empty tags
			html = html.replace(/<(.+?)>(\s|\n)+?<\/(.+?)>/gim, '');
		} catch (error) {
			this.error(error);
		}

		return html;
	}

	showPopup(data) {
		console.info('Popup triggered >>>', data);
		// @TODO: Create popup logic
	}
}

window.affiliateLandingPage = new landing();